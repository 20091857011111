.login-banner{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 500px;
}
.forgot-banner{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 500px;
}
/* @media (max-width: 640px) {
    .login-banner{
        height: 200px;
        width: auto;
    }
    .forgot-banner{
        height: 200px;
        width: auto;
    }
}
@media (max-width: 768px) {
    .login-banner{
        height: 200px;
        width: auto;
    }
    .forgot-banner{
        height: 200px;
        width: auto;
    }
} */
.line{
    margin-top: 20px;
    margin-bottom: 20px;
}

/* DARKMODE */
.dark-content .login-form-container{
    background-color: #2D2D2D;
}
.dark-content .login-bg{
    background-color: #1A1A1A;
}