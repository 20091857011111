.slider-image{
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
}
.prevBtn{
    top: 50%;
    position: absolute;
    z-index: 1;
    transform: translateX(-50px);
    cursor:pointer;
}
.prevBtn:hover{
    color:#B61E23;
}

.nextBtn{
    top: 50%;
    position: absolute;
    right:0;
    z-index: 1;
    transform: translateX(50px);
    cursor:pointer;
}
@media (max-width: 1024px) {
    .prevBtn{
        top: 100%;
        transform: translateX(0);
    }
    .nextBtn{
        top: 100%;
        transform: translateX(0);
    }
}

.nextBtn:hover{
    color:#B61E23;
}

.race-image{
    background-color: gray;
    background-size: contain;
    background-repeat: no-repeat;
}
.tag-container span{
    background-color: #B61E23;
    color: white;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-left: 0px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.article-container:hover race-title{
    text-decoration: underline;
}
.dark-content .race-bg{
    background-color: #1A1A1A;
}