.profile-picture{
    background-color: rgb(173, 173, 173);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 100px;
    width: 170px;
    height: 170px;
    z-index: 0;
}

.connectbtn-picture{
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 0;
}

.info-container{
    z-index: -1;
}
.profile-banner1{
    background-image: url("../Images/ProfileBanner1.jpg");
    background-size: cover;

}
.profile-banner2{
    background-image: url("../Images/ProfileBanner2.jpg");
    background-size: cover;

}
.profile-banner3{
    background-image: url("../Images/ProfileBanner3.jpg");
    background-size: cover;

}
.profile-banner4{
    background-image: url("../Images/ProfileBanner4.jpg");
    background-size: cover;

}
.profile-banner5{
    background-image: url("../Images/ProfileBanner5.jpg");
    background-size: cover;

}
.profile-banner6{
    background-image: url("../Images/ProfileBanner6.jpg");
    background-size: cover;

}
.profile-banner-webp1{
    background-image: url("../Images/ProfileBanner1.webp");
    background-size: cover;

}
.profile-banner-webp2{
    background-image: url("../Images/ProfileBanner2.webp");
    background-size: cover;

}
.profile-banner-webp3{
    background-image: url("../Images/ProfileBanner3.webp");
    background-size: cover;

}
.profile-banner-webp4{
    background-image: url("../Images/ProfileBanner4.webp");
    background-size: cover;

}
.profile-banner-webp5{
    background-image: url("../Images/ProfileBanner5.webp");
    background-size: cover;

}
.profile-banner-webp6{
    background-image: url("../Images/ProfileBanner6.webp");
    background-size: cover;

}
.background-dashboard-running{
    background-image: url("../Images/BannerRunning.webp");
    background-color: black;
    background-size: 120%;
    background-position: -20px -20px;
    transition: all ease-in-out;
}
.background-dashboard-walking{
    background-image: url("../Images/BannerWalking.webp");
    background-color: black;
    background-size: 120%;
    background-position: -20px -20px;
    transition: all ease-in-out;
}
.background-dashboard-workout{
    background-image: url("../Images/BannerWorkout.webp");
    background-color: black;
    background-size: 120%;
    background-position: -20px -20px;
    transition: all ease-in-out;
}
.background-dashboard-cycling{
    background-image: url("../Images/BannerCycling.webp");
    background-color: black;
    background-size: 120%;
    background-position: -20px -20px;
    transition: all ease-in-out;
}

@media (max-width: 768px) {
    .background-dashboard-running,.background-dashboard-walking
    ,.background-dashboard-workout,.background-dashboard-cycling{
        background-size: 370%;
        background-position: -20px -20px;

    }
}

.badge{
    background-image: url("../Images/charity/1.png");
    background-size: contain;
    width: 170px;
    height: 170px;
}
@media (max-width: 1024px) {
    .badge{
        height: 150px;
        width: 150px;
    }
}
@media (max-width: 375px) {
    .badge{
        height: 100px;
        width: 100px;
    }
}

.submission-picture{
    background-image: url("../Images/charity/1.png");
    background-size: contain;
    width: 100%;
    height: 100%;
}

.other-event-banner{
    background-size: contain;
}

.prevBtn-dashboard{
    bottom:-27px !important;
    position: absolute;
    right: 0 ;
    left:0 ;
    z-index: 0;
    cursor:pointer;
    margin-left: auto ;
    margin-right: auto ;
    width: 50px;
    transform: translateX(-100px);
}
.prevBtn-dashboard:hover{
    color:#B61E23;
}

.nextBtn-dashboard{
    bottom:-27px;
    position: absolute;
    right: 0 ;
    left:0 ;
    z-index: 0;
    margin-left: auto ;
    margin-right: auto ;
    width: 50px;
    transform: translateX(100px);
    cursor:pointer;
}
@media (max-width: 1024px) {
    .prevBtn-dashboard{
    }
    .nextBtn{
    }
}

.nextBtn-dashboard:hover{
    color:#B61E23;
}

.badge-container{
    display: flex !important;
    justify-content: center;
}

.submission-run-picture{
    background-image: url("../Images/submit_run_hover.png");
    background-size: contain;
    border-radius: 100px;
    width: 150px;
    height: 150px;
    background-position: center;
    background-repeat: no-repeat;
    filter: grayscale(1);
}
.submission-workout-picture{
    background-image: url("../Images/submit_workout_hover.png");
    background-size: contain;
    border-radius: 100px;
    width: 150px;
    height: 150px;
    background-position: center;
    background-repeat: no-repeat;
    filter: grayscale(1);
}
@media (max-width: 340px) {
    .submission-workout-picture ,.submission-run-picture{
        width: 100px;
        height: 100px;
    }
}
.submission-workout-picture:hover, .submission-run-picture:hover{
    filter: grayscale(0);

}
/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
    background-color: transparent;
    width: 150px;
    height: 150px;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
  }
  
  /* This container is needed to position the front and back side */
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  /* Position the front and back side */
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  
  /* Style the front side (fallback if image is missing) */
  .flip-card-front {
    color: black;
  }
  
  /* Style the back side */
  .flip-card-back {
    color: white;
    transform: rotateY(180deg);
  }

  .card-container{
      background-color: white;
  }

.activity-container{
    background-color: white;
}
.bg-nonactive{
    background-color: #9d9d9d;
}

/* DARKMODE */
.dark-content .card-container{
    background-color: #2D2D2D;
}

.dark-content .activity-container{
    background-color: #B2292C;
}

.dark-content .bg-nonactive{
    background-color: #1A1A1A;
}

.dark-content .border, .dark-content .border-b{
    border-color: #2D2D2D;
}

.bullet{
    border-radius: 100px;
    background-color: gray;
    color: white;
}

.bullet.active{
    background-color: #195fd8;
}
.bullet.activeSubmit{
    background-color: #195fd8;
}


.bullet.submit{
    /* border: gray 5px solid; */
    z-index: 1;
}
.bullet.submit::before{
    content: " ";
    position: absolute;
    border-radius: 100px;
    width: 25px;
    height: 25px;

    z-index: -1;
    border: white 1px solid;
}
@media (max-width: 768px) {
    .bullet.submit::before{
        content: " ";
        position: absolute;
        border-radius: 100px;
        width: 20px;
        height: 20px;
    
        z-index: -1;
        border: white 1px solid;
    }
}
.bullet.success{
    background-color: #43b636;
}
.bullet.failed{
    background-color: #d81919;
}


.darken{
    filter:brightness(0.6)
}

.video-thumbnail:hover .play-button{
    color: lightgray;
}