@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("Assets/css/App.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.Toastify__toast-theme--colored.Toastify__toast {
  background-color: #B61E23 !important;
  color: white !important;
  padding: 5px !important;
}
:root {
  --background: white;
  --background-secondary: white;
  --text-primary: black;
  --text-secondary: royalblue;
  --accent: purple;

  --toastify-color-light: rgb(232, 0, 0);

  --toastify-toast-min-height: 20px !important;
  --toastify-toast-max-height: 800px;
  --toastify-toast-width: 320px !important;

  --toastify-color-info: #34db7a;
  --toastify-toast-background: red;
  --toastify-text-color-info: rgb(255, 0, 0);
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;


}

[data-theme='dark'] {
  --background: black;
  --background-secondary: black;
  --text-primary: white;
  --text-secondary: grey;
  --accent: darkred;
}

.sliderTwibbon {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  background: #dddcdc;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.sliderTwibbon:hover {
  opacity: 1;
}

.sliderTwibbon::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 100px;
  background: #991B1B;
  cursor: pointer;
}

.sliderTwibbon::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #991B1B;
  cursor: pointer;
}

.vertical-shadow {
  box-shadow: 3px -9px 47px -7px rgba(0,0,0,0.22);
  -webkit-box-shadow: 3px -9px 47px -7px rgba(0,0,0,0.22);
  -moz-box-shadow: 3px -9px 47px -7px rgba(0,0,0,0.22);
}

.upload-form:hover .upload-layer{
  opacity: 0.2;
}