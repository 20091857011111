.footer-container{
    background-color: #222222;
    z-index:0;
}
.footer-item div{
    color:white;
    margin-bottom: 5px;
}
.footer-address div{
    color:white;
    font-size: small;
}

.footer-header{
    font-size: 16px;
}

.footer-icon{
    width: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.icon-instagram{
    background-image: url('../Icon/footer_icon_instagram.png');
}
.icon-whatsapp{
    background-image: url('../Icon/footer_icon_whatsapp.png');
}
.icon-telegram{
    background-image: url('../Icon/footer_icon_telegram.png');
}

.lighten{
    filter: brightness(0) invert(1);
}