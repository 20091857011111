.circle{
    border-radius: 600px;
    border: 50px solid #B61F23;
    z-index: -1;
}
.vertical-line{
    border-left: 2px solid black;
}
.exist-text{
    line-height: 30px;
}

.compro-our-community-container{
    position: relative;
    height: 600px;
    z-index: 0;
}
.community-bg{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.community-banner{
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.service-title{
    border-radius: 100px;
}

.compro-advantages-banner{
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.portfolio-year-underline{
    opacity: 0;
    transition:all ease-in-out 0.5s;
}
.year-image{
    filter: brightness(0.5);
    transition:all ease-in-out 0.5s;
}
.portfolio-year-container:hover .year-image{
    filter: brightness(0.3);
}
.portfolio-year-container:hover .portfolio-year-underline{
    opacity: 0;
}
.list-slides{
    transition: all ease-in-out 0.3s;
}
.tab-text{
    letter-spacing: 5px;
    writing-mode: vertical-lr;
}

@media (max-width: 767px) {
    .tab-text{
        letter-spacing: 1px;
        writing-mode: unset;
    }
}