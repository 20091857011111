@import url("Navbar.css");
@import url("Footer.css");
@import url("Home.css");
@import url("Login.css");
@import url("Signup.css");
@import url("Aboutus.css");
@import url("Partner.css");
@import url("Dashboard.css");
@import url("Blog.css");
@import url("Race.css");
@import url("Compro.css");

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */


body{
    font-family: 'Inter', sans-serif;
    font-weight: 500!important;
}
.color-primary{
    color:#B61E23;
}
.bg-primary{
    background-color:#B61E23;
}
.app-container{
/* width: 100vw;
height: 100vh;
position: relative;
overflow-x: scroll; */
}
.main-container{
    margin-top: 70px;
}
.main-mobile-container{
    margin-top: 0px;
}

input[type=time],input[type=text],input[type=password],input[type=date],input[type=number],textarea,select {
    padding: 5px;
    border: solid 1px lightgray;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}
.modal-card{
    z-index: 3;
}
.form-append-text{
    color: darkgray;
}
/* DARKMODE */
.bg-setting-non{
    background-color: #D1D5DB;
}
.dark-content {
background: black;
}
.dark-content p{
    color: white;
}

.dark-content hr{
    border-color: #5e5e5e;
}
.dark-content div,.dark-content h1{
    color: white;
}
.dark-content .bg-white{
    background-color: black;
}

.dark-content .main-container{
    background-color: #1A1A1A;
}

.dark-content .outline-button{
    background-color: #B2292C;
    color: white;
}
.dark-content .outline-button:hover{
    background-color: #8a2d30;
}

.dark-content input,.dark-content textarea ,.dark-content select, .dark-content .form-append-text{
    color: black;
}
.dark-content .bg-setting-non{
    background:none;
    border-left: solid 0.3px #1A1A1A;
    border-right: solid 0.3px #1A1A1A;
}

.dark-content .bg-setting-non:last-child{
    background:none;
    border-bottom: solid 0.3px #1A1A1A;
}

.dark-content .bg-setting-non:first-child{
    background:none;
    border-top: solid 0.3px #1A1A1A;
}

.swiper-pagination-bullet-active{
    background-color: #8a2d30!important;
}

.custom-classname.react-toggle--checked .react-toggle-track {
    background-color: #B61F23;
}

.custom-classname.react-toggle--checked:hover .react-toggle-track {
    background-color: grey!important;
}

.custom-classname .react-toggle-track {
    background-color: grey;
}

.custom-classname.react-toggle--focus.react-toggle .react-toggle-track {
    background-color: grey!important;
}

.custom-classname.react-toggle--checked.react-toggle--focus.react-toggle .react-toggle-track {
    background-color: #c92a30!important;
}
.react-toggle--checked .react-toggle-thumb{
    border-color: grey!important;
}

.semicircle-percent-value{
    font-size: 35px;
    font-weight: 900;
}


.m4c-sidebar .item.active{
    color: #B2292C;
}
.m4c-sidebar .item.active .info{
    display: block;
}
.m4c-sidebar .item:hover .info{
    display: block;
}

.menu-item:hover .menu-action{
    display: flex;
    transition: display ease-in-out 1s;
}


.grid-image .overlay{
    display:none;
}

.grid-image:hover .overlay{
    display:block;
    position: absolute;
    background-color: rgba(0, 0, 0, .4);
    z-index: 0;
}

.grid-image:hover .icon{
    display:block;
    z-index: 1;
}

.title-small{
    font-size: medium;
}

.dt-button.buttons-columnVisibility {
    background-color: white;
    color: black;
}
.dt-button.buttons-columnVisibility.dt-button-active{
    background-color: blue;
    color: white;
}


.clip-path-triangle {
    clip-path: polygon(0 0, 100% 0, 0% 100%);
}
.drop-shadow{
    filter: drop-shadow(-2px 5px 4px rgba(62, 62, 62, 0.4));
}
.tag {
    width: 30px;
    height: 30px;
    place-self: center;
    color: #222;
    font: bold 2em system-ui;
    filter: drop-shadow(-2px 4px 3px rgba(62, 62, 62, 0.4));
  }

  .tag::before {
    position: absolute;
    z-index: -1;
    inset: 0;
    background: #fb8c00;
    clip-path: polygon(0 0, 100% 0, 0% 100%);
    content: ''
  }

  .tag.closed::before {
    background: #D9D9D9!important;
  }
  .tag.registration::before {
    background: #16B420!important;
  }
  .tag.activity::before {
    background: #1F9CE5!important;
  }
  .tag.validation::before {
    background: #FD7F00!important;
  }
  .gradient-bg{
    background: linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(255,255,255,1) 7%, rgba(255,255,255,1) 100%);
  }

  .darkmode-toggle-container{
    position: relative;
    width: 70px;
    height: 30px;
    border-radius: 30px;
    padding-left: 3.5px;
    padding-right: 3.5px;
    background-color: #5e5e5e;
    display: flex;
    align-items: center;
    justify-content: start;
    transition: all 0.3s ease-in-out;
  }

  .darkmode-toggle-container.dark{
    background-color: #bc1515;
  }


  .darkmode-toggle-container .bullet{
    position: relative;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    position: absolute;
    background-color: white;
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
  }

  .darkmode-toggle-container.dark .bullet{
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(19.5px);
  }

  .darkmode-toggle-container .bullet .sun{
    transform: rotate(0deg) scale(1);
    transition: transform 0.3s ease-in-out;
  }

  .darkmode-toggle-container.dark .bullet .sun{
    transform: rotate(360deg) scale(0);
  }
  .darkmode-toggle-container .bullet .moon{
    filter: hue-rotate(180deg);
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: rotate(0deg) scale(0);
    transition: transform 0.3s ease-in-out;
  }

  .darkmode-toggle-container.dark .bullet .moon{
    transform: rotate(360deg) scale(1);
  }

  .dropdown-card {
    opacity: 0;
    height: 0;
    border-width: 0;
    transform: translateY(-5px);
    transition: opacity 0.1s ease-in-out,transform 0.1s ease-in-out;
  }

  .dropdown-card.show {
    opacity: 1;
    border-width: 1px;
    transform: translateY(0px);
    height:fit-content;
  }
.my-community-container::-webkit-scrollbar{
    width: 10px; /* Width of the scrollbar */
    height: 10px; /* Height for horizontal scrollbar */
}

/* Track (background of the scrollbar) */
.my-community-container::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 10px;
  }
  
  /* Thumb (scrollable part of the scrollbar) */
  .my-community-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  
  /* Thumb hover effect */
  .my-community-container::-webkit-scrollbar-thumb:hover {
    background: red;
  }

  .cancel-button-outline{
    color: #B2292C!important;
    background-color: transparent!important;
    border: solid 1px #B2292C!important;
    border-radius: 10px;
    padding: 3px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 14px!important;
  }

  .confirm-button{
    color: white!important;
    background-color: #B2292C!important;
    border: solid 1px #B2292C!important;
    border-radius: 10px;
    padding: 3px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 14px!important;
  }
  