.home-banner{
    /* background-image: url('../Images/home_banner.webp'); */
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    margin-bottom: 20px;
}
.home-container{

}
.header-line{
    height: 3px;
    width: 70px;
    background-color: #C03D41;
    border: none;
}
.how-to-container{
    /* width: 33%; */
}
.how-to-img{
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 100px;
}
.how-to-icon1{
    background-image: url('../Images/home_howto_1.png');
}
.how-to-icon2{
    background-image: url('../Images/home_howto_2.png');
}
.how-to-icon3{
    background-image: url('../Images/home_howto_3.png');
}

.exist-container{
    /* width: 50%; */
    padding: 5px;
}
.exist-banner{
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 350px;
}

.partner-item{
    height: 120px;
    width: 120px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
@media (max-width: 320px) {
    .partner-item{
        height: 100px;
        width: 100px;
    }
}
.partner-img1{
    background-image: url('../Images/corporate/1.png');
}
.partner-img2{
    background-image: url('../Images/corporate/2.png');
}
.partner-img3{
    background-image: url('../Images/corporate/3.png');
}
.partner-img4{
    background-image: url('../Images/corporate/4.png');
}
.partner-img5{
    background-image: url('../Images/corporate/5.png');
}
.partner-img6{
    background-image: url('../Images/corporate/6.png');
}
.partner-img7{
    background-image: url('../Images/corporate/7.png');
}
.partner-img8{
    background-image: url('../Images/corporate/8.png');
}
.partner-img9{
    background-image: url('../Images/corporate/9.png');
}
.partner-img10{
    background-image: url('../Images/corporate/10.png');
}
.partner-img11{
    background-image: url('../Images/corporate/11.png');
}
.partner-img12{
    background-image: url('../Images/corporate/12.png');
}
.partner-img13{
    background-image: url('../Images/corporate/13.png');
}
.partner-img14{
    background-image: url('../Images/corporate/14.png');
}
.partner-img15{
    background-image: url('../Images/corporate/15.png');
}
.partner-img16{
    background-image: url('../Images/corporate/16.png');
}

.partner-img17{
    background-image: url('../Images/corporate/17.png');
}
.partner-img18{
    background-image: url('../Images/corporate/18.png');
}
.partner-img19{
    background-image: url('../Images/corporate/19.png');
}
.partner-img20{
    background-image: url('../Images/corporate/20.png');
}
.partner-img21{
    background-image: url('../Images/corporate/21.png');
}
.partner-img22{
    background-image: url('../Images/corporate/22.png');
}
.partner-img23{
    background-image: url('../Images/corporate/23.png');
}
.partner-img24{
    background-image: url('../Images/corporate/24.png');
}
.partner-img25{
    background-image: url('../Images/corporate/25.png');
}
.partner-img26{
    background-image: url('../Images/corporate/26.png');
}
.partner-img27{
    background-image: url('../Images/corporate/27.png');
}
.partner-img28{
    background-image: url('../Images/corporate/28.png');
}
.partner-img29{
    background-image: url('../Images/corporate/29.png');
}
.partner-img30{
    background-image: url('../Images/corporate/30.png');
}
.partner-img31{
    background-image: url('../Images/corporate/31.png');
}
.partner-img32{
    background-image: url('../Images/corporate/32.png');
}
.partner-img33{
    background-image: url('../Images/corporate/33.png');
}
.partner-img34{
    background-image: url('../Images/corporate/34.png');
}
.partner-img35{
    background-image: url('../Images/corporate/35.png');
}
.partner-img36{
    background-image: url('../Images/corporate/36.png');
}
.partner-img37{
    background-image: url('../Images/corporate/37.png');
}
.partner-img38{
    background-image: url('../Images/corporate/38.png');
}
.partner-img39{
    background-image: url('../Images/corporate/39.png');
}
.partner-img40{
    background-image: url('../Images/corporate/40.png');
}
.partner-img41{
    background-image: url('../Images/corporate/41.png');
}
.partner-img42{
    background-image: url('../Images/corporate/42.png');
}
.partner-img43{
    background-image: url('../Images/corporate/43.png');
}
.partner-img44{
    background-image: url('../Images/corporate/44.png');
}
.partner-img45{
    background-image: url('../Images/corporate/45.png');
}
.partner-img46{
    background-image: url('../Images/corporate/46.png');
}