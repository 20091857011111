.partner-banner{
    background-image: url('../Images/ourpartners_banner.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 80vh;
    width: 100%;
    margin-bottom: 20px;
}

.partner-charity-img1{
    background-image: url('../Images/charity/1.png');
}
.partner-charity-img2{
    background-image: url('../Images/charity/2.png');
}
.partner-charity-img3{
    background-image: url('../Images/charity/3.png');
}
.partner-charity-img4{
    background-image: url('../Images/charity/4.png');
}
.partner-charity-img5{
    background-image: url('../Images/charity/5.png');
}
.partner-charity-img6{
    background-image: url('../Images/charity/6.png');
}
.partner-charity-img7{
    background-image: url('../Images/charity/7.png');
}
.partner-charity-img8{
    background-image: url('../Images/charity/8.png');
}
.partner-charity-img9{
    background-image: url('../Images/charity/9.png');
}
.partner-charity-img10{
    background-image: url('../Images/charity/10.png');
}
.partner-charity-img11{
    background-image: url('../Images/charity/11.png');
}
.partner-charity-img12{
    background-image: url('../Images/charity/12.png');
}
.partner-charity-img13{
    background-image: url('../Images/charity/13.png');
}
.partner-charity-img14{
    background-image: url('../Images/charity/14.png');
}
.partner-charity-img15{
    background-image: url('../Images/charity/15.png');
}
.partner-charity-img16{
    background-image: url('../Images/charity/16.png');
}
.partner-charity-img17{
    background-image: url('../Images/charity/17.png');
}
.partner-charity-img18{
    background-image: url('../Images/charity/18.png');
}
.partner-charity-img19{
    background-image: url('../Images/charity/19.png');
}
.partner-charity-img20{
    background-image: url('../Images/charity/20.png');
}

.partner-charity-img21{
    background-image: url('../Images/charity/21.png');
}