.signup-banner-container{
    height: 300px;
    z-index: 0;
}
.signup-banner{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    z-index: 0;
}
.signup-banner-text{

}
.signup-page-counter{
    width: 10px;
    height: 10px;
    border-radius: 15px;
    background-color: grey;
}
.signup-page-counter.active{
    background-color: #B9272C;
}

/* DARKMODE */
.dark-content .signup-form-container{
    background-color: #2D2D2D;
}