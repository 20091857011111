.aboutus-banner{
    background-repeat: no-repeat;
    width: 100%;
    margin-bottom: 20px;
}
.aboutus-grid-image{
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 370px;
}
@media (max-width: 375px) {
    .aboutus-grid-image{
        height: 120px;
    }
}


.aboutus-quote-banner-container{
    position: relative;
    height: 300px;
    z-index: 0;
}

.aboutus-quote-banner{
    position: absolute;
    top: 0;
    opacity: 1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    z-index: 0;
}
.aboutus-quote-banner-text{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
}

.aboutus-run-banner{
    background-size: cover;
    background-position: right;
    width: 100%;
}



.aboutus-operasional-banner-container{
    position: relative;
    height: 300px;
    z-index: 0;
}

.aboutus-operasional-banner{
    position: absolute;
    top: 0;
    opacity: 1;
    filter: blur(1);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    z-index: 0;
}
.aboutus-operasional-banner-text{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
}
.run-bg{
    background-color: #F3F4F6;
}
/* DARKMODE */

.dark-content .run-bg{
    background-color: #2D2D2D;
}

@property --num {
    syntax: "<integer>";
    initial-value: 0;
    inherits: false;
  }
  
  #increment-440 {
    animation: counter 5s ease-in-out;
    counter-reset: num var(--num);
    --num:440;
  }
  #increment-440::after {
    content: counter(num);
  }
  
  @keyframes counter {
    0% {
      --num: 0;
    }
    to {
      --num: 440;
    }
  }

  @property --num34 {
    syntax: "<integer>";
    initial-value: 0;
    inherits: false;
  }
  
  #increment-34 {
    animation: counter34 5s ease-in-out;
    counter-reset: num var(--num34);
    --num34:34;
  }
  #increment-34::after {
    content: counter(num);
  }
  
  @keyframes counter34 {
    0% {
      --num34: 0;
    }
    to {
      --num34: 34;
    }
  }