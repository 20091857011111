.blog-image{
    background-image: url('../Images/charity/12.png');
    height:100%;
    background-size: contain;
    background-repeat: no-repeat;
}
.blog-recent-image{
    background-image: url('../Images/charity/12.png');
    height:100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.blog-banner{
    background-image: url('../Images/blog_background.webp');
    background-repeat: no-repeat;
    width: 100%;
    margin-bottom: 20px;
}