.header-container{
    top: 0;
    width: 100%;
    height: 70px;
    z-index: 3;
    position: fixed;
    background-color: white;
}

.logo{
    background-image: url('../Images/logoV2.png');
    width: 90px;
    height: 35px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}

.sidebar{
    height: 100vh;
    width: 260px;
    left: 0;
    top:0;
    z-index: 500;
    background-color: var(--background);
    transition:left ease-in-out 0.25s;
}

.sidebar-hidden{
    left: -260px;
}

.navbar-icon{
    width: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    filter: grayscale(100%);
}
.menu .flex:hover .navbar-icon{
    filter: none;
}
.menu .flex:hover .font-bold{
    color:#B61E23;
}
.menu a.active .navbar-icon{
    filter: none;
}
.menu a.active .font-bold{
    filter: none;
    color:#B61E23;
}

.icon-home{
    background-image: url(../Icon/navbar_home_active.png);
}
.icon-event{
    background-image: url(../Icon/navbar_race_active.png);
}
.icon-coffee{
    background-image: url(../Icon/navbar_coffee.png);
}
.icon-profile{
    background-image: url(../Icon/navbar_profile_active.png);
}
.icon-contact{
    background-image: url(../Icon/navbar_contactus_active.png);
}
.icon-partner{
    background-image: url(../Icon/navbar_promo_active.png);
}
.icon-sync{
    background-image: url(../Icon/navbar_sync_active.png);
}
.icon-faq{
    background-image: url(../Icon/navbar_faq_active.png);
}
.icon-blog{
    background-image: url(../Icon/navbar_blog_active.png);
}
.icon-logout{
    background-image: url(../Icon/navbar_logout_active.png);
}
.icon-store{
    background-image: url(../Icon/navbar_store_active.png);
}

.icon-challenge{
    background-image: url(../Icon/navbar_challenge.png);
}

/* toggle */
.switch-toggle {
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 60px;
    height: 28px;
    background-color: grey;
    border-radius: 20px ;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 0;
}
.switch-toggle input {
    opacity: 0;
    width: 0;
    height: 0;
}
.slider-toggle:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}  
input:checked + .slider-toggle {
    background-color: #2196F3;
}
input:focus + .slider-toggle {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider-toggle:before {
-webkit-transform: translateX(32px);
-ms-transform: translateX(32px);
transform: translateX(32px);
}
.slider-toggle.round {
border-radius: 34px;
}

.slider-toggle.round:before {
border-radius: 50%;
}
/* toggle end */



/* DARKMODE */
.dark-content .header-container, .dark-content .sidebar{
    background-color: black;
}